
import { computed, defineComponent, ref, watch } from "vue";
import { LoadEntity } from "@/core/composite/composite";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import PageStatus from "@/views/page/PageStatus.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import PageService from "@/core/services/DocService";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2";
import FullFroala from "@/components/base/editor/FullFroala.vue";
import Section from "@/views/page/Section.vue";
import File from "@/views/page/File.vue";

export default defineComponent({
  name: "Page",
  components: {File, Section, FullFroala, QuickAction, PageStatus, Avatar, Row},
  props: {id: {type: String}, section: {type: String}},
  setup(props) {
    const refId = ref(props.id)
    const state = ref({
      editable: false,
      submitted: false,
      id: '',
      page: {name: '', type: '', body: '', parentId: '', description: '', status: ''}
    })
    const selectedDoc = computed(() => store.state.DocModule.selectedDoc)
    const pageEntity = computed(() => store.state.DocModule.pageEntity)
    watch(() => store.state.DocModule.pageEntity, (cb) => {
      const data: any = []
      if (cb.data.collection) {
        data.push({link: true, router: '/doc/collection/' + cb.data.collection.id, text: cb.data.collection.name})
      }
      if (cb.data.folder) {
        data.push({link: true, router: '/doc/folder/' + cb.data.folder.id, text: cb.data.folder.name})
      }
      data.push({link: true, router: '/doc/' + cb.data.id, text: cb.data.name})
      setCurrentPageBreadcrumbsWithParams('Doc', data)
    })
    watch(() => props.section, cb => {
      if (cb == undefined) {
        store.commit(Mutations.REMOVE_SELECTED_SECTION)
      }
    })
    const onEdit = (page) => {
      state.value.editable = true;
      state.value.id = page.id
      state.value.page = {
        name: page.name,
        type: page.type,
        body: page.body,
        parentId: page.parentId,
        description: page.description,
        status: page.status,
      }
    }

    store.subscribe(mutation => {
      if (mutation.type === Mutations.SET_MENU) {
        store.commit(Mutations.SET_ACTIVE_MENU, refId.value)
      }
    });
    const onSave = () => {
      state.value.submitted = true;
      PageService.update(state.value.id, state.value.page).then(res => {
        store.commit(Mutations.SET_PAGE_ENTITY, {isLoading: false, data: res})
        state.value.editable = false;
        store.commit(Mutations.SET_PAGE, res);
      }).finally(() => {
        state.value.submitted = false;
      })
    }
    return {
      ...LoadEntity(Actions.LOAD_PAGE),
      pageEntity,
      state,
      onEdit,
      onSave,
      selectedDoc,
    }
  },
  methods: {
    updateStatus(id, status) {
      PageService.updateStatus(id, status).then(() => {
        this.reloadData(id)
      })
    },
    onSelect(data) {
      // this.isSelected = true;
      store.commit(Mutations.SET_SELECTED_DOC, data);
      history.pushState(
        {},
        '',
        this.$route.path,
      );
    },
    onDeleteEntity(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(page.id).then(() => {
            store.commit(Mutations.DELETE_PAGE_ITEM, page)
            if (page.folder) {
              this.$router.push('/doc/folder/' + page.folder.id)
            } else {
              this.$router.push('/doc/collection/' + page.collection.id)
            }
          })
        }
      })
    },
  }
})

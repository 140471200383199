
import { computed, defineComponent, ref } from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import store from "@/store";
import Panel from "@/components/base/panel/Panel.vue";
import ShortPageForm from "@/views/page/ShortPageForm.vue";
import { LoadEntity } from "@/core/composite/composite";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Section",
  components: {ShortPageForm, Panel, QuickAction},
  setup() {
    const state = ref({
      show: false,
      title: '',
      id: '',
      model: {name: '', type: 'SECTION', body: '', parentId: '', description: '',}
    })
    const sections = computed(() => store.state.DocModule.sections)
    const selectedDoc = computed(() => store.state.DocModule.selectedDoc);
    const doc = computed(() => store.state.DocModule.pageEntity.data);
    return {
      sections,
      doc,
      selectedDoc,
      state,
      ...LoadEntity(Actions.LOAD_SECTIONS)
    }
  },
  methods: {
    onAddSection() {
      this.state.title = 'Add Section'
      this.state.model.parentId = this.doc.id;
      this.state.model.description = ''
      this.state.model.body = ''
      this.state.model.name = ''
      this.state.show = true;
      this.state.id = ''
    },
    onUpdate(data) {
      this.state.title = 'Add Section'
      this.state.model.parentId = this.doc.id;
      this.state.model.description = data.description
      this.state.model.body = data.body
      this.state.model.name = data.name
      this.state.id = data.id
      this.state.show = true;
    },
    onClosePanel() {
      this.state.show = false
    },
    onSavePage(data) {
      this.reloadData(data.parentId)
      this.state.show = false
    },
    onSelect(data) {
      // this.isSelected = true;
      store.commit(Mutations.SET_SELECTED_DOC, data);
      this.$router.push('/doc/' + this.doc.id + "/" + data.id)
    },
    onDelete(data) {
      console.log(data);
    }
  }
})

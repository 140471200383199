
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Panel from "@/components/base/panel/Panel.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { LoadEntity } from "@/core/composite/composite";
import DocService from "@/core/services/DocService";
import Swal from "sweetalert2";
import PageService from "@/core/services/DocService";
import { makeDownloadLink } from "@/core/helpers/functions";

export default defineComponent({
  name: "File",
  components: {BaseForm, Panel, DateTimeFormat, Avatar, QuickAction},
  setup() {
    const files = computed(() => store.state.DocModule.files);
    const doc = computed(() => store.state.DocModule.pageEntity.data);
    const state = ref({
      show: false,
      submitting: false,
      model: {file: '', parentId: ''}
    })
    return {
      files,
      doc,
      state,
      ...LoadEntity(Actions.LOAD_FILES)
    }
  },
  methods: {
    onClosePanel() {
      this.state.show = false;
    },
    addFile() {
      this.state.model = {file: '', parentId: this.doc.id}
      this.state.show = true;
    },
    handleFileChange(e: any) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.state.model.file = e.target.files[0]
    },
    onSubmit() {
      this.state.submitting = true;
      DocService.upload(this.state.model).then(res => {
        this.reloadData(res.parentId)
        this.state.show = false
      }).finally(() => {
        this.state.submitting = false;
      })
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(id).then(() => {
            this.reloadData(this.doc.id)
          })
        }
      })
    },
    onDownload(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocService.download(id).then(res => {
        makeDownloadLink(res)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    }
  }
})

